// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-article-index-js": () => import("./../src/components/Article/index.js" /* webpackChunkName: "component---src-components-article-index-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agende-js": () => import("./../src/pages/agende.js" /* webpackChunkName: "component---src-pages-agende-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cadastro-js": () => import("./../src/pages/cadastro.js" /* webpackChunkName: "component---src-pages-cadastro-js" */),
  "component---src-pages-fale-conosco-js": () => import("./../src/pages/fale-conosco.js" /* webpackChunkName: "component---src-pages-fale-conosco-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-oque-fazemos-js": () => import("./../src/pages/oque-fazemos.js" /* webpackChunkName: "component---src-pages-oque-fazemos-js" */),
  "component---src-pages-perguntas-frequentes-js": () => import("./../src/pages/perguntas-frequentes.js" /* webpackChunkName: "component---src-pages-perguntas-frequentes-js" */),
  "component---src-pages-quem-somos-js": () => import("./../src/pages/quem-somos.js" /* webpackChunkName: "component---src-pages-quem-somos-js" */),
  "component---src-pages-trabalhe-conosco-js": () => import("./../src/pages/trabalhe-conosco.js" /* webpackChunkName: "component---src-pages-trabalhe-conosco-js" */)
}

